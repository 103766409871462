/* _ezentrum_customize_productlist.scss for custom styling on all productlist pages */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_pageselect,
				.ez_pagesort {
					button.prev,
					button.next {
					}
					button.prev {
					}
					button.next {
					}
				}
				#listing {
				}
				.ez_productlist_description {
				}
				.ez_productlist_sort {
				}
				.ez_productlist {
					display: block;
					margin: 0 0 ezentrum_variables.$global-margin 0;
					padding: 0;
					border-radius:ezentrum_variables.$global-radius;
					background-color: ezentrum_variables.$white;
					background-color:ezentrum_variables.$white;
					.ez_productlist_headline {
						padding: ezentrum_variables.$global-padding;
						border-bottom: 1px solid;
						h1 {
							font-size: 1.6rem;
						}
					}
					.ez_product_wrapper {
						margin:0;
						width:100%;
						border:none;
						border-bottom: 1px solid;
						border-radius:0;
						a {
							color:ezentrum_variables.$body-font-color;
						}
						.ez_product {
							.ez_availability {
								position:relative;
								top:unset;
								right:unset;
							}
							.ez_productimage_wrapper {
								margin-top: -1 * ezentrum_variables.$global-margin;
								margin-bottom: -1 * ezentrum_variables.$global-margin;
								background-color: ezentrum_variables.$white;
								border-right:1px solid ezentrum_variables.$gray;
								.ez_productimage {
									margin: ezentrum_variables.$global-margin;
									margin-left:0;
									width: 200px;
									height: 120px;
									background-repeat: no-repeat;
									background-position: center;
									background-size: contain;
									font-size: 1.1rem;
									transition: transform 0.2s;
									background-color: ezentrum_variables.$white;
									&:hover {
										transform: scale(1.2);
										border-left:1px solid ezentrum_variables.$gray;
										border-right:1px solid ezentrum_variables.$gray;
									}
								}
							}
							.ez_normalpreis,
							.ez_sonderpreis {
								font-size: 1.125rem;
							}
						}
						&:nth-of-type(even) {
							 background: rgb(238,238,238);
							 background: -moz-linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 50%, rgba(238,238,238,1) 100%);
							 background: -webkit-linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 50%, rgba(238,238,238,1) 100%);
							 background: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 50%, rgba(238,238,238,1) 100%);
							 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeeeee",endColorstr="#eeeeee",GradientType=1); 
						}
					}
					.ez_normalpreis,
					.ez_sonderpreis {
						display: block;
						width: 150px;
						font-weight: 600;
						text-align: center;
					}
					.ez_grundpreis {
						display: block;
						width: 150px;
						text-align: center;
						color:ezentrum_variables.$alert-color;
						text-decoration:line-through;
					}
					.button,
					input[type="button"],
					input[type="submit"],
					input.button,
					a.button {
						margin-top: 0;
					}
				}
				.topartikel_headline {
					padding:ezentrum_variables.$global-padding;
					border-top-left-radius:ezentrum_variables.$global-radius;
					border-top-right-radius:ezentrum_variables.$global-radius;
					background-color:ezentrum_custom_variables.$dark-blue;
					color:ezentrum_variables.$white;
					h2 {
						margin:0;
						padding:0;
						a {
							float:right;
							font-size:ezentrum_variables.$body-font-color;
							font-weight:normal;
						}
					}
				}
				.topartikel_container {
					display: grid;
					grid-template-columns: repeat(auto-fill, minmax(220px, auto));
					margin-bottom:ezentrum_variables.$global-margin;
					border-bottom-left-radius:ezentrum_variables.$global-radius;
					border-bottom-right-radius:ezentrum_variables.$global-radius;
					background-color:ezentrum_variables.$white;
					border:1px solid ezentrum_variables.$gray;
					.topartikel_item {
						display:none;
						padding:ezentrum_variables.$global-padding;
						a {
							color:ezentrum_variables.$body-font-color;
						}
						h3 {
							height:60px;
							font-size:1rem;
							text-align:center;
							overflow:hidden;
						}
						.img_container {
							height:120px;
							text-align:center;
							img {
								max-width:80%;
								max-height:120px;
							}
						}
						.details_container {
							.ez_normalpreis,
							.ez_sonderpreis {
								font-size: 1.125rem;
								font-weight: 600;
							}
							.ez_grundpreis {
								color:ezentrum_variables.$alert-color;
								text-decoration:line-through;
							}
						}
						&:nth-of-type(1) {
							display:block;
						}
						@media screen and (min-width: 468px) {
							&:nth-of-type(2) {
								display:block;
							}
						}
						@media screen and (min-width: 690px) {
							&:nth-of-type(3) {
								display:block;
							}
						}
						@media screen and (min-width: 1124px) {
							&:nth-of-type(4) {
								display:block;
							}
						}
					}
				}
			}
		}
	}
}















