@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

@mixin slide-in-out($text-color, $border-color, $bgr-color) {
	color: $text-color;
	border: 2px solid $border-color;
	position: relative;
	z-index: 1;
	transition: 0.3s ease;
	border-radius: 5px;
	font-weight: normal;
	overflow: hidden;
	&::before, &::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		background-color: $bgr-color;
	}

	&::before {
		left: 100%;
		transition: 0.3s left ease-in-out;
		z-index: -1;
		opacity: 1;
	}
	&::after {
		right: 100%;
		transition: 0.3s right ease-in-out;
		z-index: -2;
		opacity: 0;
	}
	&:hover {
		color: $text-color;
		&:before {
			left: 0;
			z-index: -2;
			opacity: 0;
		}
		&:after {
			right: 0;
			z-index: -1;
			opacity: 1;
		}
	}
}

$light-gray: #c1cdd7;

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				#listing {
					#tabs, .container {
						#tab-shop { margin: 0; }
						#filters_box {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							#filter_box_offcanvas {
								width: 600px;
								color:  ezentrum_variables.$primary-color-hover;
								.offcanvas-header {
									padding-left: 19px;
									padding-right: 25px;
									display: block;
									position: relative;
									.offcanvas-title {
										color:  ezentrum_variables.$primary-color-hover;
										font-size: 1.2rem;
										font-weight: 700;
										text-transform: uppercase;
									}
									.btn-close {
										position: absolute;
										right: 30px;
										top: 11px;
										width: 33px;
										opacity: 1;
										background-color: transparent !important;
									}
									#ez_selected_filter {
										width: 100%;
										border: none;
										background-color: $light-gray;
										.ez_active_filter_area {
											padding-left: 18px;
											padding-right: 18px;
											padding-bottom: 18px;
										}
									}
								}
								.offcanvas-body {
									scroll-behavior: smooth;
									margin-bottom: 100px;
									>.container-fluid {
										padding: 0;
										.row { margin: 0; }
										.col, .col-12 { padding: 0; }
										&:not(:last-of-type) {
											border-bottom: 1px solid $light-gray;
										}
										.tab_public_element {
											margin: 0;
											height: fit-content;
											background-color: transparent;
											border: none;
											&#ez_price_filter { width: 100%; }
											.headline {
												color:  ezentrum_variables.$primary-color-hover;
												background-color: initial;
												box-shadow: none;
												cursor: pointer;
												font-size: ezentrum_variables.$body-font-size;
												svg.ez_icon {
													display:none;
												}
												.filter_headline {
													padding-left:0;
												}
												&[aria-expanded="true"] {
													background-color: lighten($light-gray, 10%);
												}
												&.ez-disabled { display: none; }
												.filter_hits {
													position: absolute;
													right: 60px;
													top: 50%;
													transform: translateY(-50%);
												}
												~.filter_collapse_area {
													&.ez_slide_filter_container {
														justify-content: center;
														align-items: center;
														.accordion-body {
															margin: 30px auto;
															width: 85%;
															.ez_number_input_area {
																padding-top: 20px;
																display: flex;
																gap: 20px;
															}
														}
														>.accordion-body {
															.noUi-connect {
																background-color: ezentrum_variables.$primary-color;
															}
														}
													}
													.productselection {
														padding: 0;
														margin: 0;
														max-height: 350px;
														overflow-y: auto;
														.filters {
															width: 100%;
															flex: auto;
															margin: 0;
															padding: 0;
															.filter_tablehead.ez_filter.accordion-header:not(.collapsed) {
																/*
																padding-top: 5px;
																margin-top: .4rem;
																*/
																&.dropdown-toggle {
																	border-left: 3px solid ezentrum_variables.$primary-color;
																	&::after {
																		rotate: 180deg;
																	}
																}
															}
															.filter_tablehead {
																>* { cursor: pointer; }
																~.ez_filterlist {
																	>* {
																		margin-left: 24px;
																		&:first-child { padding-top: 5px; }
																		&:last-child { padding-bottom: 5px;}
																	}
																}
															}
															.ez_filter:hover {
																input {
																	border-color: ezentrum_variables.$alert-color;
																	&:disabled {
																		border-color: rgba(0,0,0,.25);
																	}
																}
															}
														}
													}
												}
											}
											.ez_filter_selection {
												background-color: transparent;
												// refering to the search input field inside the individual filters
												input[type=text] {
													border-color: ezentrum_variables.$primary-color;
													width: 85%;
												}
												.productselection {
													.filters {
														.ez_filter {
															&.excluded_filter {
																display: none;
															}
															.ez_form_check_input {
																display: none;
																&:checked {
																	~label {
																		.ez_icon.unchecked {
																			display:none;
																		}
																		.ez_icon.checked {
																			display:block;
																		}
																	}
																}
																~label {
																	padding-left: 1.875rem;
																	font-weight: 300;
																	color: ezentrum_variables.$primary-color-hover;
																	cursor: pointer;
																	.ez_icon {
																		left:0;
																	}
																}
																&:disabled {
																	background-color: $light-gray;
																	pointer-events: none;
																	~label {
																		text-decoration: dashed;
																		color: $light-gray;
																		pointer-events: none;
																	}
																}
															}
														}
													}
												}
												&::after {
													display: none;
												}
											}
											#filter_brand_area, #filter_Region_area, #filter_Unterregion_area {
												flex: auto !important;
												.productselection {
													display: block;
													.filters {
														display: block;
														width:100%;
													}
													.ez_brandfilter, #ez-Regionfilter-Region, #ez-Unterregionfilter-Unterregion {
														display: grid;
														grid-template-columns: 50% 50%;
														@media screen and (max-width: 540px) {
															grid-template-columns: 100%;
														}
													}
												}
											}
										}
										.row:not(:last-of-type) {
											.filter_collapse_area {
												border-bottom: 1px solid ezentrum_variables.$primary-color;
											}
										}
									}
								}
								.offcanvas-footer {
									bottom: 0;
									height: 90px;
									width: 100%;
									position: absolute;
									.ez_button_white {
										background-color: ezentrum_variables.$white;
										min-height: calc(#{ezentrum_variables.$body-font-size} + (1.6 * #{ezentrum_variables.$global-padding}));
										line-height: 1.6rem;
										text-align: center;
										font-size: ezentrum_variables.$body-font-size;
										border: 1px solid ezentrum_variables.$primary-color;
										margin: 0;
										width: 350px;
										appearance: none;
										padding: .4rem;
										margin: auto;
									}
									.ez_filterbox_reset {
										width: fit-content;
										margin: 0;
										margin-left: auto;
										padding-top: 19px;
										padding-right: 19px;
									}
								}
							}
							.offcanvas-backdrop {
								background-color: darken(  ezentrum_variables.$primary-color-hover, 1%);
							}
						}
						#search_query_string {
							#orderby {
								position: relative;
								.ez_select_icon {
									position: absolute;
									top: 50%;
									transform: translateY(-50%);
									right: 1.8rem;
									color:  ezentrum_variables.$primary-color-hover;
								}
							}
							.ez_button_white {
								background-color: ezentrum_variables.$white;
								min-height: calc(#{ezentrum_variables.$body-font-size} + (1.6 * #{ezentrum_variables.$global-padding}));
								line-height: 1.6rem;
								text-align: center;
								font-size: ezentrum_variables.$body-font-size;
								border: 1px solid ezentrum_variables.$primary-color;
								margin: 0;
								width: 100%;
								appearance: none;
								padding: .4rem;
							}
							#ez_shortcut_container {
								display: flex;
								flex-wrap: wrap;
								gap: 20px;
								.ez_button_white {
									width: fit-content;
									font-size: 13px;
									@include slide-in-out(ezentrum_variables.$primary-color-hover, ezentrum_variables.$primary-color-hover, ezentrum_variables.$primary-color);
									&:first-of-type {
										width: 200px;
										background-color: #fff;
									}
								}
							}
						}
						.ez_filterbox_selectedfilter, .ez_filterbox_reset {
							padding: 0 5px;
							background-color: ezentrum_variables.$white;
						}
						.ez_filterbox_selectedfilter {
							margin-right: 10px;
							margin-bottom: 7px;
						}
						#artikel_list {
							display: flex;
							flex-wrap: wrap;
							gap: 20px;
							margin: .8rem 0;
							min-width: 300px;
							@media (max-width: 647px) { justify-content: center; }
							.ez_product_wrapper {
								width: 100%;
								margin: 0;
								flex-basis: auto;
								// flex-basis: calc((100% - 3 * 20px) / 4);
								// flex-grow: 0;
								// @media (max-width: 1250px) { flex-basis: calc((100% - 2 * 20px) / 3); }
								// @media (max-width: 930px) {  flex-basis: calc((100% - 1 * 20px) / 2); }
								// @media (max-width: 647px) {  flex-basis: calc((100% - 1 * 20px) / 1);}
							}
						}
					}
				}
			}
		}
	}
}
.wave {
    background-image: linear-gradient( 0deg, ezentrum_variables.$primary-color, ezentrum_variables.$alert-color );
    animation: loading 5s infinite linear;
	font-weight: bold;
    background-size: 100%;
    background-repeat: repeat-x;
    opacity: 1;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    height: 5vw;
    font-size: 5vw;
    display: flex;
    align-items: center;
	margin: auto;
}
@keyframes loading {
	0%{ background-size:100% 0px; background-position:0 bottom; };
	100%{ background-size:100% 100%; background-position:100% bottom; };
}

