﻿@use "../ezentrum_variables";

[data-ez-module-lightbox] img:hover {
	cursor: pointer;
}
[data-ez-open="true"] {
	display: block;
	[data-ez-animation="slide-down"] {
		-webkit-animation-name: slide-down;
		-webkit-animation-duration: 1s;
		-moz-animation-name: slide-down;
		-moz-animation-duration: 1s;
		-ms-animation-name: slide-down;
		-ms-animation-duration: 1s;
		animation-name: slide-down;
		animation-duration: 1s;
	}
	[data-ez-animation="fade-in"] {
		-webkit-animation-name: fade-in;
		-webkit-animation-duration: 1s;
		-moz-animation-name: fade-in;
		-moz-animation-duration: 1s;
		-ms-animation-name: fade-in;
		-ms-animation-duration: 1s;
		animation-name: fade-in;
		animation-duration: 1s;
	}
}
[data-ez-open="false"] {
	display: none;
	[data-ez-animation="slide-up"] {
		-webkit-animation-name: slide-up;
		-webkit-animation-duration: 1s;
		-moz-animation-name: slide-up;
		-moz-animation-duration: 1s;
		-ms-animation-name: slide-up;
		-ms-animation-duration: 1s;
		animation-name: slide-up;
		animation-duration: 1s;
		display: block;
	}
	[data-ez-animation="fade-out"] {
		-webkit-animation-name: fade-out;
		-webkit-animation-duration: 1s;
		-moz-animation-name: fade-out;
		-moz-animation-duration: 1s;
		-ms-animation-name: fade-out;
		-ms-animation-duration: 1s;
		animation-name: fade-out;
		animation-duration: 1s;
		display: block;
	}
}
.lightbox-images {
	img {
		max-height: 200px;
		max-width: 200px;
		float: left;
		border: 2px solid white;
		border-radius: 4px;
		&:hover {
			cursor: pointer;
		}
	}
	&:after {
		clear: both;
	}
}
.lightbox-container {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	font-size: 30px;
	text-align: center;
	width: 0px;
	height: 0px;
	background-color: white;
	margin: auto;
	border-radius: 4px;
	z-index: 1000001;
	.inner {
		position: relative;
		width: 100%;
		height: 100%;
		.item {
			position: absolute;
			top: 0px;
			left: 0px;
			background-color: white;
			border-radius: 4px;
			&.video {
				iframe {
					width: 100%;
					height: 100%;
				}
			}
		}
		.lightbox-buttons {
			.previous,
			.next,
			.close {
				background: transparent;
				border: none;
				padding: 0px;
				margin: 0px;
				line-height: 20px;
				.icon {
					padding: 5px;
					background-color: black;
					color: white;
					transition: transform 0.2s ease-in-out;
					&:hover {
						cursor: pointer;
						transform: scale(1.1);
					}
				}
				&:hover {
					cursor: pointer;
				}
			}
			.previous,
			.next {
				position: absolute;
				width: 35%;
				top: 10px;
				bottom: 10px;
			}
			.previous {
				left: 10px;
				text-align: left;
				padding-left: 5px;
			}
			.next {
				right: 10px;
				text-align: right;
				padding-right: 5px;
			}
			.close {
				position: absolute;
				top: 15px;
				right: 15px;
			}
		}
		.lightbox-thumbs {
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			justify-content: center;
			position: fixed;
			width: 100%;
			bottom: 0px;
			left: 0px;
			.thumb {
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-ms-flex-align: center;
				-webkit-align-items: center;
				-webkit-box-align: center;
				align-items: center;
				justify-content: center;
				background-color: white;
				img {
					max-height: 100%;
					max-width: 100%;
				}
				&:hover {
					cursor: pointer;
				}
				&:first-child,
				&:first-child img {
					border-top-left-radius: 4px;
				}
				&:last-child,
				&:last-child img {
					border-top-right-radius: 4px;
				}
			}
		}
	}
}
[data-ez-overlay] {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgba(0,0,0,0.6);
	z-index: 1000000;
	&:hover {
		cursor: pointer;
	}
	[data-ez-open="true"] {
		opacity: 0.6;
	}
	[data-ez-open="false"] {
		opacity: 0;
		z-index: -1;
	}
}







