/* _ezentrum_customize_productdetail.scss for custom styling on all productdetail pages */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_productdetail {
					display:grid;
					grid-template-columns: 65% 35%;
					grid-template-rows: auto;
					grid-template-areas:
						"headline headline"
						"image details"
						"description description";
					gap: ezentrum_variables.$global-margin;
					> div {
						border-radius:ezentrum_variables.$global-radius;
					}
					.ez_productheadline_wrapper {
						grid-area: headline;
						h1.ez_productheadline {
							font-size:1.6rem;
						}
					}
					.ez_productimage_wrapper {
						grid-area: image;
						width:100%;
						border: 1px solid ezentrum_variables.$gray;
						background-color: ezentrum_variables.$white;
						.ez_productimage {
							padding: ezentrum_variables.$global-padding;
						}
						.ez_thumb_wrapper {
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-justify-content(space-around);
							@include ezentrum_mixins.flex-flow(row, wrap);
							margin-top: ezentrum_variables.$global-margin;
							padding-top: ezentrum_variables.$global-padding;
							border-top: 1px solid ezentrum_variables.$gray;
							.ez_thumb {
								flex: 0 0 100px;
								img {
									max-width:100%;
								}
							}
						}
					}
					.ez_productdetails_wrapper {
						grid-area: details;
						padding:ezentrum_variables.$global-padding;
						width: 100%;
						border: 1px solid ezentrum_variables.$gray;
						background-color: ezentrum_variables.$white;
						.ez_productdetails {
							margin-bottom:ezentrum_variables.$global-margin;
							.ez_productdetails_table {
								width:100%;
								border-top: 2px solid rgb(238,238,238);
								border-bottom: 2px solid rgb(238,238,238);
								tr:nth-child(even) {
									background-color: rgb(238,238,238);
								}
								td {
									padding: 0.5 * ezentrum_variables.$global-padding;
								}
								.ez_productdetails_table_left {}
								.ez_productdetails_table_right {
									text-align: right;
									.ez_availability {
										margin:0;
									}
									.ez_normalpreis,
									.ez_sonderpreis {
										font-size: 1.125rem;
										font-weight: 600;
									}
									.ez_grundpreis {
										color:ezentrum_variables.$alert-color;
										text-decoration:line-through;
									}
								}
							}
						}
						.ez_productorderfields {
							display:grid;
							grid-template-columns:100%;
							grid-template-rows:auto;
							gap: ezentrum_variables.$global-margin;
							background-color: ezentrum_variables.$white;
							.amount_fields {
								margin-bottom: ezentrum_variables.$global-margin;
							}
							.ez_article_details_input,
							.article_detail_button {
								padding: 0;
								margin: ezentrum_variables.$global-margin;
							}
						}
						.ez_productdetails,
						.ez_productorderfields {
							&:after {
								display:block;
								content:"";
								margin:ezentrum_variables.$global-margin -1 * ezentrum_variables.$global-margin;
								width:calc(100% + ezentrum_variables.$global-margin + ezentrum_variables.$global-margin);
								border-bottom: 1px solid ezentrum_variables.$gray;
							}
						}
						.ez_attachments {
							a {
								color:ezentrum_variables.$body-font-color;
							}
						}
					}
					.ez_productdescription_wrapper {
						grid-area: description;
						width:100%;
						background-color: ezentrum_variables.$white;
						border: 1px solid ezentrum_variables.$gray;
						.ez_productdescription {
							padding:ezentrum_variables.$global-padding;
							.ez_expandbox {
							}
						}
						#ez_tablist {
							button {
								background: unset;
								color: ezentrum_variables.$body-font-color;
								height: 48px;
								margin: 0;
								font-size: ezentrum_variables.$body-font-size * 1.2;
								border-radius: 0;
								padding: ezentrum_variables.$global-padding;
								&[aria-selected="true"] {
									background-color: ezentrum_variables.$secondary-color;
								}
								&:not([aria-selected="true"]):hover {
									background-color: rgba(240, 240, 240,0.8);
								} 
							}
						}
						#ez_lieferant_info {
							padding: ezentrum_variables.$global-padding;
						}
					}
				}
			}
		}
	}
}
