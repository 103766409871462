/* _ezentrum_customize_checkout.scss for custom styling on all checkout pages */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

.ez_warenkorbpreview {
	.ez_warenkorbpreview_button {
	}
}
.ez_checkoutnavi {
	padding-top: ezentrum_variables.$global-padding;
	font-size: 0.6rem;
	@include ezentrum_mixins.for-size(tablet-portrait-up) {
		font-size: ezentrum_variables.$body-font-size;
	}
	.ez_checkoutnavi_item {
		.ez_checkoutnavi_icon {
			margin: 0 auto;
			width: ezentrum_variables.$checkoutnavi-icon-size;
			height: ezentrum_variables.$checkoutnavi-icon-size;
			border-radius: 50%;
			font-weight: bold;
			background-color: ezentrum_variables.$gray;
			svg.ez_icon {
				margin: auto;
				width: 36px;
				height: 36px;
				color: ezentrum_variables.$white;
			}
			&:before,
			&:after {
				position: absolute;
				top: calc((#{ezentrum_variables.$checkoutnavi-icon-size} / 2) - (0.5em / 2));
				height: 0.5em;
				content: "";
				background-color: ezentrum_variables.$gray;
			}
			&:before {
				left: 0;
				right: calc(50% + 20px);
			}
			&:after {
				left: calc(50% + 20px);
				right: 0;
			}
		}
		&:first-child .ez_checkoutnavi_icon:before {
			display: none;
		}
		&:last-child .ez_checkoutnavi_icon:after {
			display: none;
		}
		&.pre {
			.ez_checkoutnavi_icon,
			.ez_checkoutnavi_icon:before,
			.ez_checkoutnavi_icon:after {
				background-color: darken(ezentrum_variables.$primary-color,10);
			}
			.ez_checkoutnavi_name {
				color: darken(ezentrum_variables.$primary-color,10);
			}
		}
		&.current {
			.ez_checkoutnavi_icon,
			.ez_checkoutnavi_icon:before,
			.ez_checkoutnavi_icon:after {
			}
		}
		.ez_checkoutnavi_name {
			height: initial;
			line-height: 1.5em;
			display: block;
			text-align: center;
		}
	}
}
a.ez_checkoutnavi_item {
	.ez_checkoutnavi_icon,
	.ez_checkoutnavi_name {
		&:hover {
			cursor: pointer;
		}
	}
}
.ez_checkoutnavi .ez_checkoutnavi_item.current .ez_checkoutnavi_icon,
.ez_checkoutnavi .ez_checkoutnavi_item.current .ez_checkoutnavi_icon:before,
.ez_checkoutnavi .ez_checkoutnavi_item.current .ez_checkoutnavi_icon:after {
}

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_checkout {
					background-color: ezentrum_variables.$white;
					.ez_content_reduced_width {
						.ez_product_wrapper {
							padding:ezentrum_variables.$global-padding;
							&:nth-of-type(even) {
								 background: rgb(238,238,238);
								 background: -moz-linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 50%, rgba(238,238,238,1) 100%);
								 background: -webkit-linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 50%, rgba(238,238,238,1) 100%);
								 background: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 50%, rgba(238,238,238,1) 100%);
								 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeeeee",endColorstr="#eeeeee",GradientType=1); 
							}
							.ez_product {
								a h4 {
									color:ezentrum_variables.$body-font-color;
								}
								.ez_productimage {
									text-align:center;
									img {
										max-width:100%;
										max-height:120px;
									}
								}
								.ez_fields_menge {
									margin-bottom:ezentrum_variables.$global-margin;
								}
							}
						}
					}
				}
			}
		}
	}
}
