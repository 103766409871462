﻿/* _ezentrum_variables.scss for global design elements and colors */

@use "ezentrum_fonts";

/* ##### GLOBAL VARIABLES ##### */
/* global width for content area on all pages */
$global-width: 86.5rem;
/* reduced max-width for content area on special pages */
$reduced_width: 60rem;
$global-radius: 5px;
$global-margin: 0.8rem;
$global-padding: 0.8rem;
$basepath_images: "/resources/images/";
$fa-font-path: "../libraries/fontawesome/webfonts";

/* ##### PRODUCT LIST ##### */
$productlist-elementwidth: 190px;
/* minimal width for productlists - elements can grow, but not shrink */
$productlist-imageheight: 180px;

$productlist-statusicon-width: 40px;
$productlist-statusicon-height: $productlist-statusicon-width;

/* ##### FONTS ##### */
$body-font-family: "Roboto", sans-serif;
$body-font-size: 0.95394rem;
$body-font-weight: 300;
$body-line-height: 1.6rem;

/* ##### COLORS ##### */
$primary-color: #fab800;
$secondary-color: rgb(240,240,240);
/* $secondary-color: #083043; */
$alert-color: #aa1c30;
$success-color: #0c0;
$body-font-color: #333;
$header-font-color: #333;
$white: #fff;
$gray: #c1cdd7;
/* dark / light colors for mouseover */
$primary-color-hover: darken($primary-color, 5);
$secondary-color-hover: darken($secondary-color, 20);

/* ##### GRID LAYOUT ##### */
$grid-item-width: 200px;

/* ##### PAGE HEADER ##### */
$header-height: 8rem;
$header1-height: 2rem;
$header1-background-color: #002e4d;
$header1-font-family: $body-font-family;
$header1-font-size: 1rem;
$header1-font-color: #e0c166;
$header1-link-color: $body-font-color;
$header1-link-color-hover: lighten($header1-link-color, 20);

$header2-height: 4rem;
$header2-background-color: transparent;
$header2-font-color: $body-font-color;
$header2-link-color: $body-font-color;
$header2-link-color-hover: lighten($header2-link-color, 20);

$header3-height: 2rem;
$header3-background-color: transparent;
$header3-font-color: $body-font-color;
$header3-link-color: $body-font-color;
$header3-link-color-hover: lighten($header3-link-color, 20);

/* ##### PAGE FOOTER ##### */
$footer-font-family: "Roboto", sans-serif;
$footer-font-size: 1rem;
$footer-font-color: $body-font-color;
$footer-link-color: #ffffff;
$footer-link-color-hover: darken($footer-link-color, 20);

$footer1-background-color: transparent;
$footer1-font-color: $body-font-color;

$footer2-background-color: transparent;
$footer2-font-color: $body-font-color;

/* ##### SIDENAVI ##### */
$sidenavi-tablet-width: 12.5rem;
$sidenavi-width: 16rem;

/* ##### CONTENT ##### */
/* content width is calculated in relation to sidenavi width */
$content-smartphone-width: 100%;
$content-tablet-width: calc(100% - #{$sidenavi-tablet-width} - #{$global-margin} - #{$global-padding});
$content-width: calc(100% - #{$sidenavi-width} - #{$global-margin} - #{$global-padding});

/* ##### CHECKOUT-NAVI ##### */
$checkoutnavi-icon-size: 3rem;
$checkoutnavi-current-color: $primary-color;

/* ##### BREAKPOINTS FOR MEDIA QUERIES ##### */
/* smartphone-only */
$smartphone-max-size: 599px;
/* tablet-portrait-up */
$tablet-portrait-min-size: 600px;
/* tablet-landscape-up */
$tablet-landscape-min-size: 900px;
/* desktop */
$desktop-min-size: 1200px;
/* large-desktop */
$large-desktop-min-size: 1800px;
