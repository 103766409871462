﻿/* _ezentrum_custom_variables.scss */
// Header / Footer - Gradient-Background
$bg-gradient: linear-gradient(to right, #ffffff, #4b4f6f);

// light grey
$main-bg-color: #f0f0f0;

// dark blue
$dark-blue:#083043;

